import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';

import { Form } from '../../atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { AlertType, InfoAlert } from '../../atoms/InfoAlert';
import { Copy } from '../../atoms/Typography';
import { ChevronRightIcon } from '../../atoms/Icons';
import { Colors } from '../../../styles/colors';
import { CheckboxInput } from '../../atoms/Form/CheckboxInput';

export const CloseJobModal = ({ handleCancel, handleSubmit }: InviteUserOnBoardModalProps) => {
  const onSubmit: SubmitHandler<FormData> = async () => {
    await handleSubmit({});
  };

  return (
    <>
      <H2>Close</H2>
      <InfoAlert type={AlertType.Warning}>
        <Copy marginBottom={16}>Are you certain you want to close this Job Application?</Copy>
        <Copy styleLevel={2}>
          By confirming, it will be shared with the JobsBoard.io community. Kindly confirm your decision.
        </Copy>
      </InfoAlert>
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <CheckboxInput name="confirm" label="I confirm" isChecked={false} />
        <WrapperButtons>
          <SecondaryButton inline onClick={handleCancel}>
            Cancel
          </SecondaryButton>

          <PrimaryButton type="submit" inline={true} iconRight={<ChevronRightIcon color={Colors.White} size={1} />}>
            Close
          </PrimaryButton>
        </WrapperButtons>
      </Form>
    </>
  );
};

const validationSchema = Yup.object().shape({
  confirm: Yup.boolean().oneOf([true], 'You must confirm to close the job'),
});

type FormData = Yup.InferType<typeof validationSchema>;

interface InviteUserOnBoardModalProps {
  handleSubmit: (a: any) => void;
  handleCancel: (a: any) => void;
}
